import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useActiveSessionQuery } from 'modules/sessions';
import { isRoundClosing } from 'modules/Rounds';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import Dashboard from './Dashboard';
import Session from './Session';
import Tutorial from './Tutorial';
import GroupSettings from '../../GroupSettings';

const App = ( { user } ) => {
  const { activeSession, ...sessionQuery } = useActiveSessionQuery();
  // eslint-disable-next-line max-len
  const pathname = useSelector(
    ( { router } ) => router && router.location && router.location.pathname,
  );

  useEffect( () => {
    if ( !sessionQuery.isFetching ) sessionQuery.refetch();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [pathname] );

  useEffect( () => {
    if ( activeSession && activeSession.currentRound ) {
      const round = find( activeSession.rounds, { id: activeSession.currentRound.id } );

      if ( isRoundClosing( round ) ) {
        return toast.loading( 'is closing rounds', {
          id: 'roundclosing',
          autoClose: false,
        } );
      }
      toast.dismiss( 'roundclosing' );
    }
  }, [activeSession] );
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/tutorial" component={Tutorial} />
      <Route path="/sessions" component={Session} />
      <Route
        exact
        path="/group-settings"
        render={props => <GroupSettings {...props} user={user} />}
      />
      <Route path="/">
        <Redirect to="/" />
      </Route>

    </Switch>
  );
};

export default App;
