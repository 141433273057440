/* eslint-disable prefer-destructuring */
import React from 'react';
import HeadConfig from 'components/custom/HeadConfig';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import HeaderImage from 'assets/images/kotler/sectionHeaders/info-market.jpeg';
import { connect } from 'react-redux';

import _ from 'lodash';
import { injectIntl } from 'react-intl';
import * as am4core from '@amcharts/amcharts4/core';
import CategoryService from 'services/Category';
import {
  clearTotalCustomer,
  fetchTotalCustomerRequest,
} from 'modules/Customers/redux/actions/totalCustomersActions';
import {
  clearMarketProducts,
  fetchMarketProductsRequest,
} from 'modules/Products/redux/actions/marketProductsActions';
import {
  clearInfoProducts,
  fetchInfoProductsRequest,
} from 'modules/Products/redux/actions/infoProductsActions';
import {
  clearPriceProducts,
  fetchPriceProductsRequest,
} from 'modules/Products/redux/actions/PriceProductsActions';
import {
  clearLastRoundProducts,
  fetchLastRoundProductsRequest,
} from 'modules/Products/redux/actions/lastRoundProductsActions';
import {
  clearLastRoundServices,
  fetchLastRoundServicesRequest,
} from 'modules/Services/redux/actions/LastRoundServicesActions';
import {
  clearAllRoundsServices,
  fetchAllRoundsServicesRequest,
} from 'modules/Services/redux/actions/AllRoundsServicesActions';
import {
  clearLastRoundBundles,
  fetchLastRoundBundlesRequest,
} from 'modules/Bundles/redux/actions/LastRoundBundlesActions';
import {
  clearAllRoundsBundles,
  fetchAllRoundsBundlesRequest,
} from 'modules/Bundles/redux/actions/AllRoundsBundlesActions';
import MarketContainer from 'modules/Market/containers/MarketContainer';
import {
  clearMarketStimate,
  fetchMarketStimateRequest,
} from 'modules/Market/redux/actions/MarketStimateActions';
import SettingsActions from '../../../../../../../../store/reducers/Settings';

class MarketCharts extends React.Component {
  isEnterprise = false;
  mkmode = {};
  componentDidMount() {
    this.clearAllData();
    const { user } = this.props;
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;
    this.loadComponent();
  }

  // componentDidUpdate( nextProps ) {
  //   const { locale } = this.props;
  //   if ( nextProps.locale !== locale )
  //     this.loadComponent();
  //   }
  // }

  componentWillUnmount() {
    this.clearAllData();
    am4core.disposeAllCharts();
  }
  clearAllData = () => {
    const {
      toClearTotalCustomers,
      toClearMarketProducts,
      toClearInfoProducts,
      toClearPriceProducts,
      toClearLastRoundServices,
      toClearAllRoundsServices,
      toClearLastRoundBundles,
      toClearAllRoundsBundles,
      toClearMarketSizeStimate,
      toClearLastRoundProducts,
    } = this.props;
    toClearTotalCustomers();
    toClearMarketProducts();
    toClearInfoProducts();
    toClearPriceProducts();
    toClearLastRoundServices();
    toClearAllRoundsServices();
    toClearLastRoundBundles();
    toClearAllRoundsBundles();
    toClearMarketSizeStimate();
    toClearLastRoundProducts();
  };
  async loadComponent() {
    const {
      user,
      toggleLoading,
      fetchTotalCustomers,
      fetchMarketProducts,
      fetchInfoProducts,
      fetchPriceProducts,
      fetchLastRoundProducts,
      fetchLastRoundServices,
      fetchAllRoundsServices,
      fetchLastRoundBundles,
      fetchAllRoundsBundles,
      fetchMarketSizeStimate,
    } = this.props;

    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    fetchTotalCustomers( user.activeSession.id );
    fetchLastRoundProducts( user.activeSession.id, this.license.workGroup.id );
    fetchAllRoundsServices( user.activeSession.id );
    fetchLastRoundServices( user.activeSession.id, this.license.workGroup.id );
    fetchAllRoundsBundles( user.activeSession.id );
    fetchLastRoundBundles( user.activeSession.id, this.license.workGroup.id );
    fetchMarketSizeStimate( user.activeSession.id );

    CategoryService.getCategories( { 'filters[session]': user.activeSession.id } ).then( ( response ) => {
      if ( !response.ok ) return;
      toggleLoading( false );
      const categories = response.data.data;
      this.categories = categories;
      this.setState( categories );
      if ( categories && categories.length ) {
        categories
          .filter( ( { name } ) => this.isEnterprise || name === 'Cellular' )
          .map( ( category ) => {
            fetchMarketProducts(
              user.activeSession.id,
              this.license.workGroup.id,
              category.id,
            );
            fetchInfoProducts(
              user.activeSession.id,
              this.license.workGroup.id,
              category.id,
            );
            fetchPriceProducts( user.activeSession.id, category.id );

            return category.id;
          } );
      }
    } );
  }
  render() {
    const { user } = this.props;
    this.mkmode = user.activeSession.fields;
    this.isEnterprise = user.activeSession.isEnterprise;

    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'InformationDashboard-market' },
          ]}
        />
        <KotlerSectionHeader title="market" image={HeaderImage} />
        {this.mkmode && (
          <MarketContainer
            {...{
              mkmode: this.mkmode,
              categories: this.categories,
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ( { user, settings } ) => {
  const { locale } = settings;
  return { user, locale };
};
const fetchers = {
  fetchTotalCustomers: fetchTotalCustomerRequest,
  fetchMarketProducts: fetchMarketProductsRequest,
  fetchInfoProducts: fetchInfoProductsRequest,
  fetchPriceProducts: fetchPriceProductsRequest,
  fetchLastRoundProducts: fetchLastRoundProductsRequest,
  fetchLastRoundServices: fetchLastRoundServicesRequest,
  fetchAllRoundsServices: fetchAllRoundsServicesRequest,
  fetchLastRoundBundles: fetchLastRoundBundlesRequest,
  fetchAllRoundsBundles: fetchAllRoundsBundlesRequest,
  fetchMarketSizeStimate: fetchMarketStimateRequest,
};

const cleaners = {
  toClearTotalCustomers: clearTotalCustomer,
  toClearMarketProducts: clearMarketProducts,
  toClearInfoProducts: clearInfoProducts,
  toClearPriceProducts: clearPriceProducts,
  toClearLastRoundProducts: clearLastRoundProducts,
  toClearLastRoundServices: clearLastRoundServices,
  toClearAllRoundsServices: clearAllRoundsServices,
  toClearLastRoundBundles: clearLastRoundBundles,
  toClearAllRoundsBundles: clearAllRoundsBundles,
  toClearMarketSizeStimate: clearMarketStimate,
};
const mapDispatchToProps = {
  toggleLoading: SettingsActions.toggleLoading,
  ...fetchers,
  ...cleaners,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( MarketCharts ) );