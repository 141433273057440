import React from 'react';
import { capitalizeFirstLetter } from 'utils/Util';

const getText = ( ) => {

  const api_url = process.env.REACT_APP_API_URL;
  if ( api_url.search('dev') !== -1 ){
    return 'Dev';
  } else if ( api_url.search('pre') !== -1   ){
    return 'Pre';
  } else {
    return '';
  }

}
const TextEnviroment = ({activeSession,isAdmin}) => (
  <>
    {( activeSession && activeSession.distributionType ) ? capitalizeFirstLetter(activeSession.distributionType)  : ''}
    {isAdmin && 'Admin'}
    { getText() }
  </>
);

export default TextEnviroment;